<template>
  <!-- modal -->
  <b-modal
    :id="'modal-edit-user-'+userEditData.id"
    :ref="'modal-edit-user-'+userEditData.id"
    :title="'Utente ' + userEditData.fullName"
    ok-title="Aggiorna"
    cancel-title="Cancella"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="editUserForm">
      <form @submit.stop.prevent="validationForm">

        <b-form-group
          ref="name"
          :state="nameState"
          label="Nome"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <validation-provider
            #default="{ errors }"
            name="Nome"
            rules="required"
          >
            <b-form-input
              id="name-input"
              v-model="userData.first_name"
              :state="first_nameState"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :state="last_nameState"
          label="Cognome"
          label-for="last_name-input"
          invalid-feedback="Cognome is required"
        >
          <validation-provider
            #default="{ errors }"
            name="Last Name"
            rules="required"
          >
            <b-form-input
              id="last_name-input"
              v-model="userData.last_name"
              :state="last_nameState"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          ref="email"
          :state="emailState"
          label="Email"
          label-for="email-input"
          invalid-feedback="Email is required"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="email-input"
              v-model="userData.email"
              :state="emailState"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="divider my-3">
          <div class="divider-text">
            Solo per modificare la password
          </div>
        </div>

        <!-- Password -->
        <validation-provider
          #default="validationContext"
          name="password"
          rules="password"
        >
          <b-form-group
            label="Password"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="userData.password"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-text>Your password must be 8 characters long, with a capital letter and a special character.</b-form-text>
          </b-form-group>
        </validation-provider>

      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, BFormText, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BFormText,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userEditData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      first_name: '',
      nameState: null,
      last_nameState: null,
      emailState: null,
      passwordState: null,
      submittedNames: [],
    }
  },
  methods: {
    validationForm() {
      this.$refs.editUserForm.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.first_nameState = null
      this.last_nameState = null
      this.emailState = null
      this.passwordState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs[`modal-edit-user-${this.userEditData.id}`].toggle('#toggle-btn')
      })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      id: props.userEditData.id,
      first_name: props.userEditData.first_name,
      last_name: props.userEditData.last_name,
      email: props.userEditData.email,
      password: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('staff/updateUser', userData.value)
        .then(() => {
          emit('refetch-data')
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
